body {
  background: #fff;
  height: inherit;
  overflow-y: auto;
}
.light .sysbar {
  line-height: 48px;
  position: fixed;
  width: 100%;
  z-index: 1;
}

.light .content {
  margin: 48px 3.5%;
}

.light .tile {
  background: #fff;
  padding: 3px 15px;
}

.light input,
.light textarea {
  width: auto;
}
.light label {
  line-height: 2.5;
  font-size: 14px;
  font-weight: 500;
}
.light input.validation.valid {
  margin-bottom: 10px;
}
.light .tile .header {
  padding-bottom: 0;
}
.light .row {
  flex-basis: max-content;
}

.textfield-description-msg {
  margin-bottom: 4px;
  margin-top: 5px;
  font-size: 12px;
  line-height: 14px;
  color: #878787;
}
.margin-top20 {
  margin-top: 20px;
}
.margin-left20 {
  margin-left: 20px;
}

.light .card:hover {
  cursor: default;
}

.captcha_box {
  border: 1px solid #878787;
  margin: 15px;
  min-height: 50px;
  width: 66%;
  display: inline-block;
}

.captcha_icon {
  font-size: 36px;
  width: 20%;
  display: inline-block;
  position: relative;
  top: -66px;
  font-weight: 900;
}
.captcha_icon a:hover {
  text-decoration: none !important;
  border-bottom: none !important;
}
.captcha_container .card .header {
  /* background:#4D97ED; */
  padding: 15px 15px 0 15px;
  font-size: 24px;
  color: #080808;
}
.error-text {
  color: red;
}

.captcha_container .card {
  /* -webkit-box-shadow: -1px 2px 12px -1px rgba(0,0,0,0.45);
-moz-box-shadow: -1px 2px 12px -1px rgba(0,0,0,0.45);
box-shadow: -1px 2px 12px -1px rgba(0,0,0,0.45); */
  padding: 0 0 15px 0;
}

/* ----------Menu---------------- */

#hamnav {
  width: 100%;
  background: #000;
}

/* (B) HORIZONTAL MENU ITEMS */
#hamitems {
  display: flex;
}
#hamitems a {
  flex-grow: 1;
  flex-basis: 0;
  padding: 10px;
  color: white;
  text-decoration: none;
  text-align: center;
}
#hamitems a:hover {
  background: #af0d0d;
}

/* (C) HIDE HAMBURGER */
#hamnav label,
#hamburger {
  display: none;
}

/* [ON SMALL SCREENS] */
@media screen and (max-width: 768px) {
  /* (A) BREAK INTO VERTICAL MENU */
  #hamitems a {
    box-sizing: border-box;
    display: block;
    width: 100%;
    border-top: 1px solid #333;
  }

  /* (B) SHOW HAMBURGER ICON */
  #hamnav label {
    display: inline-block;
    color: white;
    background: #a02620;
    font-style: normal;
    font-size: 1.2em;
    padding: 10px;
  }

  /* (C) TOGGLE SHOW/HIDE MENU */
  #hamitems {
    display: none;
  }
  #hamnav input:checked ~ #hamitems {
    display: block;
  }
}

/* ----------Menu---------------- */

/* footer */
.footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  background: #000;
  color: #fff;
  line-height: 24px;
}

.red-icon {
  color: #a02620;
  padding-right: 7px;
}
.red-icon-text {
  vertical-align: middle;
}
.padding-span {
  padding-top: 5px;
}
.red_text {
  color: #a02620;
  font-weight: bold;
}
.blue_text {
  color: rgb(57, 11, 237);
  font-weight: bold;
  font-size: 18px;
  background-color: rgb(233, 231, 116);
}
.light input.validation.valid,
.light textarea.validation.valid {
  border: 1px solid #329864;
  margin-bottom: 4px;
  box-shadow: none;
}
.light input.validation.invalid {
  border: 1px solid #bb0b02;
  margin-bottom: 4px;
  box-shadow: none;
}
.validation-msg::before {
  font-family: 'Ericsson Icons';
  display: inline;
  vertical-align: baseline;
  margin-right: 4px;
  position: relative;
  top: 1px;
  content: '\e9b2';
  color: #bb0b02;
}
.btnSubmit{
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.errorMessageDiv{
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.invalidCode{
  display: flex;
  justify-content: flex-start;
  margin-left: 14px;
  color: red;
}
